@import 'config';
@import 'libs/styles/responsive';

.contact-form  {
  padding-bottom: 2rem;
  
  input[type="text"],
  input[type="number"],
  input[type="email"],
  textarea {
    border: 1px solid $color-blue;
    padding: .5rem 1rem .3rem 1rem;
    border-radius: 2rem;
    display: block;
    width: 100%;
  }

  textarea {
    height: 6.25rem;

    @include target(md, xl) {
      height: auto;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;

    &+label {
      padding-left: 2.875rem;
      position: relative;
      display: block;
      cursor: pointer;
      line-height: 1.2rem;
      
      @include target(md, xl) {
        line-height: 2rem;
      }

      &:before {
        content: "";
        position: absolute;
        top: -0.2rem;
        left: 0;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 1px solid $color-blue;

        @include target(md, xl) {
          top: 0.3rem;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0.05rem;
        left: .25rem;
        display: block;
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        background-color: $color-blue;
        opacity: 0;

        @include target(md, xl) {
          top: .6rem;
        }
      }
    }

    &:checked {
      &+label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .form__invisible-label {
    position: absolute;
    left: -9999px;
  }

  .form__row {

    @include target(md, xl) {
      display: flex;
      align-items: flex-end;
      justify-content: stretch;
      gap: 3rem;
    }
  }

  .form__row--first {
    .form__column {
      margin-bottom: 1.5rem;

      @include target(md, xl) {
        margin-bottom: 1rem;
      }
    }
  }


  .form__column {
    margin-bottom: 1.5rem;
    width: 100%;
    
    @include target(md, xl) {
      margin-bottom: 3rem;
    }
  }
  .form__row--small-gap {

  }

  .form__column--birthday {
    label {
      margin-bottom: .8rem;
      display: block;
    }
  }

  .form__multifield {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: .5rem;
  }


}