@import 'config';
@import 'libs/styles/responsive';

.sponsors {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  gap: 1.75rem;
  justify-content: center;
  
  @include target(md, xl) {
    gap: 3.125rem;
    justify-content: flex-start;
  }
}

.sponsors__sponsor a {
  text-decoration: none;
  color: white;
}

.sponsors__sponsor img {
  max-width: 4rem;
  max-height: 20px;
  display: block;

  @include target(md, xl) {
    max-width: 6rem;
  }
}
