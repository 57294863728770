@import 'config';
@import 'libs/styles/responsive';

.study {
  border-bottom: 1px solid black;
  padding: 4.5rem 0 1.5rem 0;
}

.study:first-child {
  padding-top: 2rem;
}

.study:last-child {
  border-bottom: 0;
}

.study__section {
  margin-bottom: 1rem;

  @include target(md, xl) {
    margin-bottom: 3rem;
  }
}