@import 'config';
@import 'libs/styles/responsive';

.button {
  display: inline-block;
  cursor: pointer;
  background-color: $color-blue;
  white-space: nowrap;
  border-radius: 40px;
  color: white;
  outline: 0;
  border: 0;
  text-decoration: unset;
  margin-bottom: .3rem;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  line-height: 1.2rem;
  transition: color 125ms ease-in-out,
              background-color 125ms ease-in-out;

  @include target(md, xl) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.button--large {
  padding: 0.875rem 1.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;

  @include target(md, xl) {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    padding: 1.1875rem 2.25rem;
  }
}

.button--contact {
  background-color: transparent;
  color: $color-blue;
  border: 1px solid $color-blue;
  font-weight: 400;
}

.button--toggler {
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
}

.button--contact:hover {
  background-color: $color-blue;
  color: white;
}

.button--black {
  background-color: black;
}

.button--gray {
  background-color: #CCCCCC;
}

.button--green {
  background-color: #09BC8A;
}
