@import 'config';
@import 'libs/styles/responsive';

.filter-form {
  padding-top: 1rem;

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
  }

  input[type="checkbox"] + label {
    cursor: pointer;
    text-align: center;
    padding: 0.4375rem 1.25rem;
    border-radius: 2rem;
    color: white;
    background-color: var(--theme);
    border: 5px solid var(--theme);
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    display: block;
    margin-bottom: 0.3rem;
    transition: color 125ms ease-in-out,
                background-color 125ms ease-in-out;

    @include target(md, xl) {
      display: inline-block;
      margin-bottom: 0;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  input[type="checkbox"]:checked + label {
    background-color: white;
    color: var(--theme);
    border: 5px solid var(--theme);
  }
}

#id_phases,
#id_patient_type,
#id_tumor,
#id_hospital {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;

  @include target(md, xl) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.625rem;
    margin-bottom: 0.625rem;
  }
}

#id_patient_type {
  --theme: #09BC8A;
}

#id_tumor {
  --theme: #0066ff;
}

#id_hospital {
  --theme: black;
}

#id_phases {
  --theme: #CCCCCC;

}

.all-filters {
  padding-top: 1rem;
  margin-bottom: 1.25rem;
  padding-bottom: 4rem;

  @include target(md, xl) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.selected-filters {
  display: none;

  @include target(md, xl) {
    display: block;
  }
}

.button--toggler {
  display: block !important;

  @include target(md, xl) {
    display: none !important;
  }
}

.fields.hidden {
  display: none;

  @include target(md, xl) {
    display: block !important;
  }
}

.field-block-group {

  @include target(md, xl) {
    display: flex;
    flex-wrap: wrap;
      flex-direction: row;
      gap: 0;

      .field-block:first-child {
        margin-right: 0.625rem;
      }
  }

}

.field-block {
  margin-bottom: 2rem;

  @include target(md, xl) {
    margin-bottom: 0;
  }
}

.form__errors {
color: $color-red;
  padding: 0.5rem 0;
  line-height: 1rem;
  font-size: .75rem;

  @include target(md, xl) {
    padding: 1rem 0;
    line-height: 2rem;
    font-size: 1.4rem;
  }
}

.form-column--errors {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    border-color: $color-red !important;
  }
  input[type="checkbox"] {
    &+label {
      &:before {
        border-color: $color-red !important;
      }
    }
  }
}
