@import 'config';
@import 'libs/styles/responsive';

.footer {
  background-color: black;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  overflow: hidden;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: auto;

  a {
    color: white;
    text-decoration: underline;
  }

  @include target(xs, md) {
    position: fixed !important;
  }
  
  @include target(md, xl) {
    position: static;
  }
}

.footer--collapsed {
  overflow: hidden;
}

.collapsed-text {
  display:none;
  .footer--collapsed & {
  display: inline;
}
}

.expanded-text {
  display:inline;
  .footer--collapsed & {
  display: none;
}
}

.footer__top {
  padding: 1.625rem 0;
  overflow: auto;

  @include target(md, xl) {
    padding: 1rem 0;
  }
}

.footer__top-columns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  flex-direction: row;

  @include target(md, xl) {
    align-items: center;
  }
}

.footer__bottom {
  height: 0;
}

.footer__bottom-inner {
  padding: 1.625rem 0;

  @include target(md, xl) {
    padding: 1rem 0;
  }
}