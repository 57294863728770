@import 'config';
@import 'libs/styles/responsive';

body {
  min-height: 100vh;
}

body.scroll-locked {
  overflow: hidden;
}

.container {
  padding: 0 1rem;

  @include target(md, xl) {
    max-width: 108rem;
    padding: 0 3rem;
  }
}

.container--centered {
  margin-left: auto;
  margin-right: auto;
}

.container--full {
  width: 100%;
}

.row--align-stretch {
  align-items: stretch;
}

.row--align-center {
  align-items: center;
}

.column--8 {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  
  @include target(lg, xl) {
    width: 67.73%;
  }
}

.column--full {
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  gap: .5rem;
  
  @include target(md, xl) {
    gap: 1.5rem;
  }
}

.row--stack-on-sm {
  flex-direction: column;

  @include target(lg, xl) {
    flex-direction: row;
  }
}

.column--full {
  width: 100%;
}

.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}