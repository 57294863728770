.impressum-toggle {
  appearance: none;
  outline: 0;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  color: white;
}

.impressum {
  max-width: 740px;
}