.text-accordion__paragraph {
  height: 3.6em;
  overflow: hidden;
  margin-bottom: 0;
}

.text-accordion__button {
  background-color: transparent;
  padding: 0;
  outline: 0;
  border: 0;
  color: #888;
  font-size: 1em;
  line-height: 17px;
  cursor: pointer;
}
