@import 'config';
@import 'libs/styles/responsive';
@import 'app/styles/fonts';

*,
*::before,
*::after {
  @include nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "AkkuratLLWeb-Regular";
}

.strong {
  font-weight: $fw-bold;
}

.headline1 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: $fw-bold;
}

.active-filters {
  font-family: "AkkuratLLWeb-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 4.81rem;
  border: 0;
  outline: 0;
}

.results-count {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.8125rem;

  @include target(md, xl) {
    font-size: 2rem;
    line-height: 2.375rem;
  }
}

.main-title {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.06rem;
  color: $color-blue;
  margin-bottom: 1.5rem;

@include target(md, xl) {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 3.125rem;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $color-blue;
  margin-bottom: 1rem;

  @include target(md, xl) {
  font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.lead {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;

  @include target(md, xl) {
    font-weight: 400;
  font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.study__section {
  margin-bottom: 3rem;
  font-size: 0.875rem;
  line-height: 1rem;

  @include target(md, xl) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    //   font-size: 1.5rem;
    // line-height: 1.8125rem;
    font-weight: 700;
  }
}

.overlay__close {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-size: 1rem;
    line-height: 1.2rem;
color: #000000;
  @include target(md, xl) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.accordion {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.2rem;
  letter-spacing: 0.03em;

  @include target(md, xl) {
    font-size: 1.5rem;
    line-height: 1.6875rem;
  }
}

.accordion h3 button {
  font-weight: 700;
}

.study__meta {
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;

  @include target(md, xl) {
    font-size: 1.0625rem;
    line-height: 1.25rem;
  }
}

.study__section:first-child {
  color: $color-blue;
}

.study__title h3 {
  font-weight: 700;
}

.study__section p {
  font-weight: 400;
}

.original-title {
  font-weight: normal;
  color: #888;
}

ul {
  list-style: disc;
  padding-left: 2rem;
  margin-bottom: 1.2em;
}


strong {
  font-weight: bold;
}

p {
  margin-bottom: 1.2em;
}

b {
  font-weight: 700;
}

p a,
li a {
  color: black;
}

ol {
  list-style: auto;
  padding-left: 3rem;
  margin-bottom: 1.2em;
}

textarea {
  font-family: inherit;
}

.contact-form {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  textarea {
    font-size: 0.84375rem;
    line-height: 1.6875rem;
    font-weight: 400;

    @include target(md, xl) {
      font-size: 1.125rem;
      line-height: 2.25rem;
    }
  }
  label {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.03em;

    @include target(md, xl) {
      font-size: 1.125rem;
      line-height: 1rem;
    }
  }
}

.impressum-toggle {
  font-family: "AkkuratLLWeb-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-right: 15px;
  @include target(md, xl) {
    font-size: 1.3125rem;
    line-height: 2.125rem;
    margin-right: 0;
  }
}

.footer {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.footer h2 {
  margin-bottom: 1rem;
}

@mixin by-tags {
  h1 {
    @extend .headline1;
  }

  // h2 {
  //     @extend .headline2;
  // }

  // h3 {
  //     @extend .headline3;
  // }

  // p {
  //     @extend .text;
  // }

  strong {
    @extend .strong;
  }

  // a:not(.btn) {
  //     @extend .text-link;
  // }
}


// djangocms_text_ckeditor plugin wrapper class
.text-plugin-content,
.cke_editable {
  @include by-tags;
}

.cke_editable {
  padding: 10px;
}
