@import 'config';
@import 'libs/styles/responsive';

.video {
  margin-bottom: 1.25rem;
  position: relative;
  border-bottom: 1px solid black;
  
  @include target(md, xl){
    margin-bottom: 2.25rem;
    height: calc(100vh - 420px);
    min-height: 200px;
    max-height: calc(56.25vw - 53px);
  }
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video__poster {
  appearance: none;
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  inset: 0;
  z-index: 2;
  cursor: pointer;
  transition: opacity 125ms ease-in-out;
  opacity: 1;
}

.video__poster--hidden {
  opacity: 0;
  pointer-events: none;
}

.video__poster img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.video__player {
  width: 100%;
  display: block;
}

.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(1, 1) translate(-50%, -50%);
  width: 8.4rem;
  height: 3.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 102, 255, 0.3);
  border-radius: 80px;
  transition: transform 125ms ease-in-out;

  @include target(md, xl) {
    width: 14.5rem;
    height: 6.75rem;
  }
}

.video__play img {
  width: 2.75rem;
  height: 3rem;
  transform: translateX(0.3rem);

  @include target(md, xl) {
    width: 3.75rem;
    height: 4.21875rem;
    transform: translateX(0.625rem);
  }
}

.video__poster:hover .video__play {
  transform: translate(-50%, -50%) scale(1.1, 1.1);
}