@import 'config';
@import 'libs/styles/responsive';

.border-left {
  @include target(lg, xl) {
    border-left: 1px solid black;
    padding-left: 1.5625rem;
  }
}

.content-space-between {
  justify-content: space-between;
}

.content-end {
  justify-content: flex-end;
}

.text-right-on-lg {
  @include target(md, xl) {
    text-align: right;
  }
}

.text-center {
  text-align: center;
}

.only-lg {
  @media (max-width:1023px) {
    display: none;
  }
}

.only-sm {
  @media (min-width:1024px) {
    display: none;
  }
}