@import 'config';
@import 'libs/styles/responsive';

.results {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -8.3rem;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: top 125ms ease-in-out;
  overflow: auto;
  max-height: calc(100vh - 9.7rem);
  min-height: 200px;
  overflow: hidden;

  @include target(md, xl) {
    position: static;
    top: unset;
    left: unset;
    right: unset;
    margin-top: unset !important;
    transform: unset !important;
    max-height: unset !important;
    padding: 0;
    min-height: unset;
    padding-bottom: 9rem;
  }
}

.filter-results {
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;
  padding: .8rem 0;
  border-bottom: 1px solid black;
  min-height: 6.5rem;
  display: none;

  input {
    width: 100%;
    padding-right: 0;
  }

  button {
    appearance: none;
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
  }

  @include target(md, xl) {
    display: flex;
  }
}

.results--expanded {
  top: 13.3rem;
  overflow: auto;
}

.results--expanded .results-count__toggle {
  transform: translateX(-50%) rotate(0);
}

.results__inner {
  border-top: 1px solid black;
  padding: .8rem 0 2rem;
  min-height: 100vh;
  
  @include target(md, xl) {
    min-height: unset;
    padding: 2rem 0 2rem;
  }
}

.results-count {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.results-count__toggle {
  transform: translateX(-50%) rotate(180deg);
  border: 0;
  outline: 0;
  appearance: none;
  background-color: transparent;
  transition: transform 125ms ease-in-out;
  position: absolute;
  top: 1rem;
  left: 50%;
  &:after {
    content: "";
    position: absolute;
    width: 100vw;
    left: 50%;
    top: 50%;
    height: 60px;
    transform: translateX(-50%) translateY(-50%);
  }
}
