@import 'config';
@import 'libs/styles/responsive';

.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion__item {
  margin-bottom: 1.8rem;
}

.accordion__button {
  appearance: none;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  display: block;
  width: 100%;
  color: $color-blue;
  text-align: left;
  font-size: 1.25rem;
    line-height: 1.5rem;
}

.accordion__content {
  overflow: hidden;
  height: 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.accordion__content-inner {
  padding-top: 1.8rem;
}