@import 'config';
@import 'libs/styles/responsive';

.header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  z-index: 3;

  @include target(md, xl) {
    position: static;
  }
}

.header__inner {
  padding: 1rem 0;
  border-bottom: 1px solid black;
  
  @include target(md, xl) {
    padding: 1.3rem 0;
  }
}

.header__nav {
  font-family: "AkkuratLLWeb-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.01em;
  cursor: pointer;
  appearance: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0;
}

.header__nav__desktop {

  display: none;
  @include target(md, xl) {
    display: initial;
  }
}

.header__nav__mobile {
  @include target(md, xl) {
    display: none;
  }
}

.content-wrapper {
  padding-top: 5.0625rem;
  padding-bottom: 5.6875rem;
  min-height: 100vh;
  margin-bottom: 1.5rem;

  @include target(md, xl) {
    padding-top: 0;
    padding-bottom: 0;
    min-height: calc(82vh - 186px);
  }
}