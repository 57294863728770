@import 'config';
@import 'libs/styles/responsive';

.overlay {
  position: fixed;
  top: 0;
  bottom: 7.5rem;
  right: 0;
  padding-bottom: 4.125rem;
  width: 100%;
  background-color: white;
  box-shadow: -5px 0px 12px rgba(0, 0, 0, 0.15);
  z-index: 3;
  overflow: auto;
  transform: translateX(105%);
  transition: 250ms ease-in-out;

  @include target(md, xl) {
    max-width: 66.66%;
    bottom: 0;
    padding-bottom: 0;
  }
}

.overlay--open {
  transform: translateX(0);
}

.overlay__header {
  height: 10rem;

  @include target(md, xl) {
    height: 19.5rem;
  }

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
}

.overlay__close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;

  @include target(md, xl) {
    top: 1.5rem;
    right: 3rem;
  }
}

.overlay__content {
  padding: 1.5rem 1.5rem .7rem 1.5rem;

  @include target(md, xl) {
    padding: 3rem 3rem 5.5rem 3rem;
  }
}

.overlay__content--push-top {
  padding: 5rem 1.5rem 0.7rem 1.5rem;

  @include target(md, xl) {
    padding: 6rem 3rem 1.375rem 3rem;
  }
}

.overlay__thanks {
  height: 100%;

  h3 {
    margin-bottom: 2.8rem;

    @include target(md, xl) {
      margin-bottom: unset;
    }
  }

  @include target(md, xl) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

[data-overlay-slot] {
  height: 100%;
}

[data-contact-content],
.overlay__content {
  height: inherit;
}

.overlay__actions {
  text-align: center;

  @include target(md, xl) {
    text-align: start;
  }
}