/*********** Colors ***********/

$color-black: #000000;
$color-white: #ffffff;
$color-blue: #0066ff;
$color-green: #c7ff00;
$color-red: hsl(1,50%,54%);

/*************************************/

/*********** Breakpoints ***********/

$breakpoints: ( // Breakpoint Definition
    xs: 0,
    // Extra small screen / phone
    sm: 576px,
    // Small screen / phone
    md: 768px,
    // Medium screen / tablet
    lg: 992px,
    // Large screen / desktop
    xl: 1280px // Extra large screen / wide desktop
);

/******************************/

/*************** Fonts ***************/

$font-OpenSans: 'OpenSans';

// /*************************************/