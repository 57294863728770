@import 'config';
@import 'libs/styles/responsive';

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  white-space: nowrap;
}

.logo__left {
  font-style: normal;
  font-weight: 700;
  font-size: .9rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  border-right: 1px solid black;
  padding-right: 1rem;

  @include target(md, xl) {
    font-size: 1.5625rem;
    line-height: 1.625rem;
  }
}

.logo__right {
  padding-left: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.65625rem;
  line-height: 0.8125rem;

  @include target(md, xl) {
    font-size: 1.05rem;
    line-height: 1.25rem;
  }
}